
import * as React from 'react';
import {useEffect }from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardContent';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import LikeIconBorder from '@mui/icons-material/FavoriteBorder';
import LikeIcon from '@mui/icons-material/Favorite';
import EyeIcon from '@mui/icons-material/Visibility';
import DoneIconBorder from '@mui/icons-material/CheckCircleOutline';
import DoneIcon from '@mui/icons-material/CheckCircle';
import SendIcon from '@mui/icons-material/Share';
import Tooltip from '@mui/material/Tooltip';
import Draggable, {DraggableEvent, DraggableData} from "react-draggable";
import bucketlist from '../data/bucketlist.json';


import '../App.css';





function BucketList() {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  const [checked, setChecked] = React.useState([-1]);
  const [showMore, setShowMore] = React.useState<boolean[]>([false]);
  const [like, setLike] = React.useState<boolean[]>([false]);
  const [done, setDone] = React.useState<boolean[]>([false]);
  const IMAGE_SIZE=20;

  const handleShowMoreChange = (index: number) => {
    const newBoolArray = [...showMore];
    newBoolArray[index] = !newBoolArray[index];
    setShowMore(newBoolArray);
  };

  const handleLikeChange = (index: number) => {
    const newBoolArray = [...like];
    newBoolArray[index] = !newBoolArray[index];
    setLike(newBoolArray);
  };

  const handleScrollTo = (index: number) => {
    const newBoolArray = [...like];
    newBoolArray[index] = !newBoolArray[index];
    setLike(newBoolArray);
  };

  const handleDoneChange = (index: number) => {
    const newBoolArray = [...done];
    newBoolArray[index] = !newBoolArray[index];
    setDone(newBoolArray);
    console.log(done.filter(Boolean).length)
  };


  

  return (

    <React.Fragment>
      <CssBaseline />

      <Container>
      <Card sx = {{ maxWidth: 800, margin:"auto", marginTop:9}}>
      <CardMedia 
        //sx={{ height: 250 }}      
        //image="/img/beforeidie.jpg"
        title="Top 20 things to do before I die"
      />

      <CardContent>

      <Typography textAlign="center"  component={'span'}  gutterBottom variant="h3"  sx={{textColor:"#ddd"}} fontFamily="Tilt Warp">
              100 things to achieve in a lifetime
        </Typography>
        <Stack direction="row" alignItems="center" m={2} ml={4} spacing={1} >
          <LikeIcon/><Typography variant="body2" color="#bbb"  pr={2}> 1 456 likes</Typography>
          <EyeIcon /><Typography variant="body2" color="#bbb">39k views</Typography>
        </Stack>
        <Stack direction="row" mt={1}spacing={2} className="listIconsBucketlist" sx={{overflowX:"scroll"}}>
        {bucketlist.map( (bucket, key) => {
        return ( <Tooltip title={bucket.name}><Avatar onClick={() => handleScrollTo(key)} src={"/icons/"+bucket.icon} variant="square" sx={{ overflowX:"hidden"}} ></Avatar></Tooltip>  )})}
        </Stack>
        <Typography  component={'span'} variant="body2" color="text.secondary">
        <Box mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={(done.filter(Boolean).length) / bucketlist.length * 100} sx={{height:"12px"}} color="secondary"/>
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography  component={'span'} variant="body2" color="text.secondary">{`${Math.round( (done.filter(Boolean).length) / bucketlist.length * 100)}%`}</Typography>
        </Box>
      </Box>

      <Grid container >


        
        {bucketlist.map( (bucket, key) => {
        return (

          <Grid item xs={12} mt={2}>
            <Card sx={{ background:"rgba(255,255,255,0.4)"}} >
            <CardHeader>
              <Box sx={{position:"absolute", top:"1", border:'1px'}}>
                <Typography variant="h4" color="#bbb" fontFamily="Tilt Warp" >{key+1}</Typography>
              </Box>
              <Stack direction="row" p={3} alignItems="center">
                <Avatar src={"/icons/"+bucket.icon} variant="square"></Avatar>
                <Stack direction="column">
                    <Typography pl={2}  fontFamily="Tilt Warp" variant="h4">{bucket.name}</Typography>
                    <Typography pl={2}  variant="body2">Details</Typography>
                </Stack>                
              </Stack>
            </CardHeader>
            <CardMedia               
              sx={{ height: 350 }}      
              image={"/img/work/" +bucket.img}
              title="Top 20 things to do before I die"
            >
              
             
             
            </CardMedia>
            <Grid container  ml={3} mt={1}>

              <Grid item xs={12}>
              </Grid>
              <Stack direction="row" m={1}>
              <IconButton aria-label="Add" onClick={() => handleLikeChange(key)} >
                {like[key] ? <LikeIcon /> : <LikeIconBorder /> }
              </IconButton>
              <IconButton aria-label="Done" onClick={() => handleDoneChange(key)}>
                {done[key] ? <DoneIcon /> : <DoneIconBorder /> }
              </IconButton>
              <IconButton aria-label="Send">
               <SendIcon/>
              </IconButton>
              </Stack>
              <Divider style={{marginTop:10, marginBottom:30}} />
  
              <Grid item xs={12} sm={12}>
                <Typography component={'p'} p={2} sx={{marginRight:3, marginBottom:3}}>{showMore[key]  ? bucket.description : `${bucket.description.substring(0, 50)}...`}
                {!showMore[key] ?  <Button  onClick={() => handleShowMoreChange(key)}>"more"</Button> : <></>}</Typography>
              </Grid>
             </Grid>
              </Card>
          </Grid> 

        )   
  
        })}
          
       
        <Grid item xs={6}>
         

        </Grid>
      </Grid>
        </Typography>
      </CardContent>
      <CardActions>
      <Grid>
      </Grid>
      </CardActions>
    </Card>

    <Grid>  
        <Grid item xs={2}>    
          <Button
            color="primary"
            startIcon={<Avatar src={'/btn/facebook.png'} />}
          >
          </Button>
        </Grid>
        <Grid item xs={2}>       
          <Button
            color="primary"
            startIcon={<Avatar src={'/btn/instagram.png'} />}
          >
          </Button>
        </Grid>
        <Grid item xs={2}>      
          <Button
            color="primary"
            startIcon={<Avatar src={'/btn/tiktok.png'} />}
          >
          </Button>
        </Grid>
        <Grid item xs={2}>      
          <Button
            color="primary"
            startIcon={<Avatar src={'/btn/print.png'} />}
          >
          </Button>
        </Grid>
      </Grid>
 
      </Container>

    </React.Fragment>
  );
}


export default BucketList;
