import React, {useState, useEffect}  from 'react';
import {
  Link
} from "react-router-dom";
import { Typography} from '@mui/material/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { Card, CardMedia, Tooltip, Box, Chip } from '@mui/material';


function Bucketlist() {

    return (
      <>
      
        <Link  to='/user' style={{ textDecoration: 'none', color: 'black'}}>
        <Tooltip title="Create yours" sx={{display: {sm:"block", lg:'none'}}}>
        <Card sx={{height: '70px', width:'100%', marginTop:1}}>
          <CardMedia               
                sx={{ height: 350, borderColor: "#3da", borderWidth:'3px' }}      
                title="Add"
              >

                <Typography 
                            fontFamily="Tilt Warp"
                            color="white"
                            sx={{ padding:2, display: { sm: 'none', md: 'none', lg: 'block' } }}>Create yours</Typography>
          <Box sx={{ paddingTop:'25%', paddingLeft:'25%', display: { sm: 'block', md: 'block', lg: 'block' } }}>
           <FontAwesomeIcon icon={faPlusCircle} fontSize='2rem'/>
           </Box>
          </CardMedia>
        </Card>
        </Tooltip>
        </Link>

        <Link key='mainLink' to='/bucketlist' style={{ textDecoration: 'none', color: 'black'}}>
        <Tooltip title="Family" sx={{display: {sm:"block", lg:'none'}}}>
        <Card sx={{height: '70px', width:'100%', marginTop:1}}>
          <CardMedia               
                sx={{ height: 350 }}      
                image={"/img/family.jpg"}
                title="Family"
              >
                <Typography 
                            fontFamily="Tilt Warp"
                            color="white"
                            sx={{ padding:2, display: { sm: 'none', md: 'none', lg: 'block' } }}>Family</Typography>
          </CardMedia>
        </Card>
        </Tooltip>
        </Link>

        <Link  to='/bucketlist' style={{ textDecoration: 'none', color: 'black'}}>
        <Tooltip title="Couple" sx={{display: {sm:"block", lg:'none'}}}>
        <Card sx={{height: '70px', width:'100%', marginTop:1}}>
          <CardMedia               
                sx={{ height: 350 }}      
                image={"/img/beforeidie.jpg"}
                title="Couple"
              >
                <Typography             
                fontFamily="Tilt Warp"
            color="black"
            sx={{ padding:2,  display: { sm: 'none', md: 'none', lg: 'block' } }}>Life goals</Typography>
          </CardMedia>
        </Card>
        </Tooltip>
        </Link>

        <Link  to='/bucketlist' style={{ textDecoration: 'none', color: 'black'}}>
        <Tooltip title="Summer" sx={{display: {sm:"block", lg:'none'}}}>
          <Card sx={{height: '70px', width:'100%', marginTop:1}}>
          <CardMedia               
                sx={{ height: 350 }}      
                image={"/img/summer.jpg"}
                title="Family"
              >
                <Typography             
                fontFamily="Tilt Warp"
                color="white"
                sx={{ padding:2,  display: { sm: 'none', md: 'none', lg: 'block' } }}>Summer</Typography>
          </CardMedia>
        </Card></Tooltip>
        </Link>
      </>
    );
}

export default Bucketlist;
