import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Avatar, Button, IconButton, Stack, Typography}  from '@mui/material'
import ChangeCircle from '@mui/icons-material/ChangeCircle'
import DeleteCircle from '@mui/icons-material/DeleteRounded'

import '../../App.css'

export function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    margin:"auto",
    marginBottom: 25,
  };
  
  const styleText = {
    fontSize: "12px",
    width: "100px",
  };

  const handleClick = (event) => {
    console.log("Click");
  };


  return (
<>
    <div class="draggableCircle" ref={setNodeRef} style={style} {...attributes} {...listeners}>

      
        <div id="circleStyle" onClick={handleClick} class={"circle " + props.style}>
          <div class="number">{props.number+1}</div>

          <Avatar sx={{ margin:"auto",  height: "60px", width: "60px" }}  src={"/icons/"+props.icon} variant="square"></Avatar>
      </div>
  
      <Typography style={styleText} color="white" >{props.name}</Typography>
      </div>
</>  
   

  );
}