import React, {useState, useEffect}  from 'react';
import {
  Link
} from "react-router-dom";
import { Typography} from '@mui/material/';

import Stack from '@mui/material/Stack';


function Logo() {

    return (
        <Link key='mainLink' to='/' style={{ textDecoration: 'none', color: 'black'}}>
        <Stack

          pb={1}
          direction="row"   
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          spacing={1}>
   
      <img src="/logo.png" width={55} height={55}/>
          <Typography        
            fontFamily="Tilt Warp"
            color="white"
            sx={{ 
            display: { sm: 'none', md: 'none', lg: 'block' } }}
          >        
         GOFORDO
          </Typography>
      
          </Stack>    </Link>
    );
}

export default Logo;
