
import * as React from 'react';
import {useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Link, useLocation
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import HomeElement from './mini/HomeElement';
import 'react-perfect-scrollbar/dist/css/styles.css';


import activities from '../data/bucketlist.json';
import themes from '../data/themes.json';

import '../App.css';


function Home() {




  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  const [chipSelected, setChipSelected] = React.useState<boolean[]>([false]);


  const IMAGE_SIZE = 60;



  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));




  return (

    <React.Fragment>
      <CssBaseline />

      <Typography ml={3} sx={{ fontWeight: 600, fontSize: '5vh' }}  variant="h5">Tendance </Typography>

      <Grid key="GridExplorer" container sx={{width:'100%'}} alignItems="center" justifyContent="center">
        {activities.map( (activity, key) => {
        return (
      <Grid key={"activity" + key} item m={1} xs={5} sm={4} md={3} lg={2}>
          <HomeElement activity={activity} key={key}/>
      </Grid>
        )
        })}
      </Grid>



</React.Fragment>
  );
}

export default Home;
