import React, {useState, useEffect}  from 'react';
import { styled } from '@mui/material/styles';
import {
  Link, useLocation
} from "react-router-dom";
import { Stack, Box, Toolbar, Grid, Typography, Menu, Container, Divider, Avatar, Button, Tooltip, MenuItem, BottomNavigation, BottomNavigationAction } from '@mui/material/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'


const ArrowButton = styled(Button)({
  textTransform: 'none',
  width: 45,
  height: 45,
  color: "white",
  background: "",
  fontSize: 25,
  borderRadius: 30,
  justifyContent: 'center',
  '&:hover': {
    background: 'rgba(50,50,50,0.4)',
    // Modify other hover styles as needed
  },
});

const LogInButton = styled(Button)({
  textTransform: 'none',
  width: 100,
  height: 45,
  color: "white",
  background: "linear-gradient(to right, #76df, #66af)",
  fontSize: 18,
  borderRadius: 30,
  justifyContent: 'center',
  '&:hover': {
    background: 'blue',
    // Modify other hover styles as needed
  },
});

const SignUpTypo = styled(Typography)({
  textTransform: 'none',
  width: 100,
  height: 45,
  color: "#ddd",
  fontSize: 18,
  fontWeight: 100,
  paddingTop:8,
  justifyContent: 'center',
  '&:hover': {
    color: 'white',
    // Modify other hover styles as needed
  },
});



const TopMenu = () => {

  return (
    
    <>
    <Grid container mt={1}>
      <Grid item xs={4} >
        <Stack direction="row" alignItems="center" justifyContent="left" spacing={1} ml={2}>
          
        <ArrowButton> <FontAwesomeIcon icon={faChevronLeft}/> </ArrowButton>
        <ArrowButton>  <FontAwesomeIcon icon={faChevronRight}/>  </ArrowButton>
        </Stack>
      </Grid>
      <Grid item xs={4} ></Grid>
      
      <Grid item  xs={4}>
        <Stack direction="row" alignItems="center" justifyContent="right">
          <Link to="/signup" style={{textDecoration:'none'}}><SignUpTypo>Sign up</SignUpTypo></Link>
          <Link to="/login" ><LogInButton variant="contained"  sx={{ marginRight:2}}>Log in</LogInButton></Link>
        </Stack>
      </Grid> 
    </Grid>

    
</>
  );
};
export default TopMenu;
