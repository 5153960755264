
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Link, useLocation
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormatListNumbered from '@mui/icons-material/FormatListNumbered';

import themes from '../../data/themes.json';

import '../../App.css';


function ThemeList() {


  const [checked, setChecked] = React.useState([1]);
  const IMAGE_SIZE = 60;

  function handleToggle(value: number)  {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };



  return (

    <React.Fragment>
      <CssBaseline />
      <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
      }}
    >
      
<Container fixed maxWidth="md">

      <Grid container  sx={{pt : 3}} alignItems="center" justifyContent="center">

        {themes.map( (theme, key) => {
        return (
      <Grid xs={12} md={6}>
        <Card sx = {{maxWidth: 1100, margin:2 }}>
          <CardMedia
            sx={{ height: 250 }}
            image={"/img/"+theme.img}
            title="Top 20 things to do before I die"
          />
        <CardContent sx={{ height: 70 }}>

          <Typography  component={'span'} className=".overlay" gutterBottom variant="h5" fontFamily="Tilt Warp">
                {theme.theme}
          </Typography>
         {/* <Typography sx={{mt:2}} className=".overlay" variant="body2" gutterBottom>
                {theme.description}
        </Typography> */}
          </CardContent>
          {/*<CardActions>
          <Link to="/bucketlist" className="btn btn-primary"  style={{ textDecoration: 'none', color: 'black', margin:'auto'}}><Button variant="outlined" color="secondary">Try </Button></Link>
        </CardActions>*/}
        </Card>
      </Grid>
        )
        })}
</Grid>
</Container>
</div>
</React.Fragment>
  );
}

export default ThemeList;
