import React, {useState, useEffect}  from 'react';
import { styled } from '@mui/material/styles';
import {
  Link, useLocation
} from "react-router-dom";
import { AppBar, Box, Toolbar, Grid, Typography, Menu, Container, Divider, Avatar, Button, Tooltip, MenuItem, BottomNavigation, BottomNavigationAction } from '@mui/material/';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {  faCompass } from '@fortawesome/free-solid-svg-icons'
import {  faSquarePlus } from '@fortawesome/free-solid-svg-icons'
import {  faUser } from '@fortawesome/free-solid-svg-icons'

import Logo from './Logo'

const pages = ['Create', 'Share', 'Rencontrer'];

const SignUpButton = styled(Button)({
  textTransform: 'none',
  width: 100,
  height: 45,
  color: "white",
  backgroundColor: "#692ba3",
  fontSize: 18,
  marginTop: 10,
  borderRadius: 10,
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: 'blue',
    // Modify other hover styles as needed
  },
});

const BottomMenu = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const location = useLocation();

  console.log("pathname " + location.pathname);




  useEffect(() => {

    if(location.pathname === "/home") {
      setValue(0);
    }
    else if(location.pathname === "/search") {
      setValue(1);
    }
    else if(location.pathname === "/explore") {
      setValue(2);
    }
    else if(location.pathname === "/create") {
      setValue(3);
    }
    else if(location.pathname === "/user") {
      setValue(4);
    }
    else {
      setValue(5);
    }
  }, [location] )
  return (
    
    <>
    <Grid container sx={{display:{xs:'block', sm:'none'}}} mt={1}>
      <Grid item xs={4} ><Logo/></Grid>
      
      {/*<Grid item xs={4}><Link to="/login"><SignUpButton variant="contained" sx={{padding: 1}}>Sign Up</SignUpButton></Link></Grid> **/}
    </Grid>

    <AppBar  position="fixed" sx={{ display: { xs: 'block', sm: 'none' }, zIndex: (theme) => theme.zIndex.drawer + 1, top: 'auto', bottom: '0', borderRadius:0, backgroundColor: "#222" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          <Box sx={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}>

            <BottomNavigation
              showLabels
              value={value}
              style={{backgroundColor:"#3335"}}
              onChange={(event, newValue) => {
                setValue(newValue);
              } }
            >
              <BottomNavigationAction label="Home" icon={<FontAwesomeIcon icon={faHome} />} component={Link}  to="/" />
              <BottomNavigationAction label="Search" icon={<FontAwesomeIcon icon={faSearch} />} component={Link} to="/search" />    
              <BottomNavigationAction label="Explore" icon={<FontAwesomeIcon icon={faCompass}/>} component={Link} to="/explore" />
              <BottomNavigationAction label="Create" icon={<FontAwesomeIcon icon={faSquarePlus} />} component={Link} to="/create" />
              <BottomNavigationAction label="User" icon={<FontAwesomeIcon icon={faUser} />} component={Link} to="/user" />
            </BottomNavigation>

          </Box>
      
       
        </Toolbar>
      </Container>
    </AppBar>
    
</>
  );
};
export default BottomMenu;
