
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import {
  Link, useLocation
} from "react-router-dom";
import {Grid, Stack, FormControl, Box, Button, Typography, TextField } from '@mui/material/';
import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import ExploreIcon from '@mui/icons-material/Explore';
import mantras from '../data/mantras.json';

import Logo from './mini/Logo'

import '../App.css';


function SignUp() {




  return (

<Grid container minHeight="100vh" textAlign="center">
  <Grid item xs={12} md={6} sx={{background:"black"}}>
    <Logo/>

    <Typography m={2} variant="h4">Sign Up</Typography>

    <Stack
      component="form"
      direction="column"
      sx={{
        '& .MuiTextField-root': { m: 1 },
        width: '100%'
      }}
      noValidate
      autoComplete="off"
    >
      <FormControl fullWidth>
        <TextField
          sx={{m:2}}
          required
          id="email"
          label="Email"
        />

        <TextField
          sx={{m:2}}
          required
          id="password"
          label="Password"
        />
    </FormControl>
      </Stack>
  </Grid>
  <Grid item xs={0} md={6}>
  <div className="video-container">
    <video autoPlay loop muted id="video">
        <source src="/movies/trailer.mp4" type='video/mp4' />
        </video> 
        </div>
  </Grid>
</Grid>



  );
}

export default SignUp;
