
import * as React from 'react';
import {useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Link, useLocation
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import LikeIconBorder from '@mui/icons-material/FavoriteBorder';
import LikeIcon from '@mui/icons-material/Favorite';
import EyeIcon from '@mui/icons-material/Visibility';
import DoneIconBorder from '@mui/icons-material/CheckCircleOutline';
import DoneIcon from '@mui/icons-material/CheckCircle';
import SendIcon from '@mui/icons-material/Share';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import activities from '../data/bucketlist.json';
import themes from '../data/themes.json';

import '../App.css';


function Explore() {




  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  const [like, setLike] = React.useState<boolean[]>([false]);
  const [done, setDone] = React.useState<boolean[]>([false]);
  const [chipSelected, setChipSelected] = React.useState<boolean[]>([false]);
  const [theme, setTheme] = React.useState<string[]>([]);
  const [checked, setChecked] = React.useState([1]);

  const IMAGE_SIZE = 60;

  function handleToggle(value: number)  {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

  const handleClickChip = (index: number, theme: string) => {
    const newBoolArray = [...chipSelected];
    newBoolArray[index] = !newBoolArray[index];
    setChipSelected(newBoolArray);
    console.log(showThemesSelected());
    
    
  };

  const showThemesSelected = (() => {
  var themeList: string[]; 
  var index = 0;
  themes.map((theme, key) => {
    if(chipSelected[key]) {
      console.log(themes[key].theme);
      themeList.fill(themes[key].theme);
      index++;
    }
    return themeList;
  })
});
  
  const handleLikeChange = (index: number) => {
    const newBoolArray = [...like];
    newBoolArray[index] = !newBoolArray[index];
    setLike(newBoolArray);
  };

  const handleScrollTo = (index: number) => {
    const newBoolArray = [...like];
    newBoolArray[index] = !newBoolArray[index];
    setLike(newBoolArray);
  };

  const handleDoneChange = (index: number) => {
    const newBoolArray = [...done];
    newBoolArray[index] = !newBoolArray[index];
    setDone(newBoolArray);
    console.log(done.filter(Boolean).length)
  };


  return (

    <React.Fragment>
      <CssBaseline />

<Container key="containerEplorer" maxWidth="md">

      <Grid key="GridExplorer" container  sx={{pt : 3}} alignItems="center" justifyContent="center">
      <Grid key="GridItem0" item xs={12}>
        
      <Search key="Search">
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              sx={{width:"100%"}}
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
      </Grid>
</Grid>
</Container>
      <Stack mt={1} direction="row" className="listIconsBucketlist" sx={{overflowX:"scroll"}}>


      {themes.map( (theme, key) => {
        return (
         <> <div key={"divOpacity" +key} style={{opacity: chipSelected[key] ? 1 : 0.2}}>
            <Chip key={"Chip"+key} size="medium" sx={{mt:2, mr:1}} label={theme.theme} onClick={() => handleClickChip(key, theme.theme)} style={{color:'white', backgroundColor:theme.color}} />
        </div>
        </>
        )
      })}

      </Stack>
      <Container key="containerEplorer2" maxWidth="md">
      <Grid key="GridExplorer" container  sx={{pt : 1}} alignItems="center" justifyContent="center">
        {activities.map( (activity, key) => {
        return (
      <Grid key={"activity" + key} item md={12} lg={6}>
        <Card key={activity.name} sx = {{ margin:2, height:'520px', background:"rgba(255,255,255, 0.6)" }}>
          <CardMedia 
            key={"activityCM" + key}
            sx={{ height: 250 }}
            image={"/img/work/"+activity.img}
            title={activity.name}
          />
        <CardContent key={"activityCC" + key}  >
          <Stack  key={"activityStack" + key} direction="row">
          <Avatar key={"activityAvatar" + key} sx={{mr:2}} src={"/icons/"+activity.icon} variant="square"></Avatar>
          <Typography key={"activityTypo" + key} component={'span'} className=".overlay" gutterBottom variant="h5" fontFamily="Tilt Warp">
                {activity.name}
          </Typography>
          </Stack>
          <Stack direction="row" m={1} spacing={3} key={"activityStack2" + key}>
              <Stack direction="column" alignItems="center">
                <IconButton key={"like" + key} aria-label="Like" onClick={() => handleLikeChange(key)} >
                {like[key] ? <LikeIcon /> : <LikeIconBorder /> }
              </IconButton>
              <Typography sx={{fontSize: "10px" }}>Like</Typography>
              </Stack>
              <Stack direction="column" alignItems="center">
                <IconButton key={"done" + key} aria-label="Done" onClick={() => handleDoneChange(key)}>
                  {done[key] ? <DoneIcon /> : <DoneIconBorder /> }
                </IconButton>
                <Typography sx={{fontSize: "10px" }}>Done</Typography>
              </Stack>
              </Stack>
              <Typography key={"ActivityTypo2" + key} sx={{mt:2}} className=".overlay" variant="body2" gutterBottom>
                  {activity.description}
          </Typography>     
          <Typography key={"ActivityTypo3" + key} sx={{mt:2}} className=".overlay" variant="body2" gutterBottom>
                
          </Typography>
          </CardContent>
          <CardActions>
         
        </CardActions>
        </Card>
      </Grid>
        )
        })}
</Grid>
</Container>


</React.Fragment>
  );
}

export default Explore;
