
import * as React from 'react';
import {useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Link, useLocation
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import HomeElement from './mini/HomeElement';
import 'react-perfect-scrollbar/dist/css/styles.css';


import activities from '../data/bucketlist.json';
import themes from '../data/themes.json';

import '../App.css';


function Activity() {




  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  



  return (

    <React.Fragment>
      <CssBaseline />

      <Typography ml={3} sx={{ fontWeight: 600, fontSize: '5vh' }}  variant="h5">{activities[0].name} </Typography>

      <Grid key="GridExplorer" container sx={{width:'100%'}} alignItems="center" justifyContent="center">

      <Grid  item xs={12}>
      <CardMedia 
            key={"activityImage" }
            image={"/img/work/"+ activities[0].img}
            sx={{width:'90%', marginLeft:'5%', marginTop:'5%', height:"25vh",  borderRadius: 3}}
            title={activities[0].name}
          />
       
       <Typography ml={3} mt={4}>{activities[0].description} </Typography>

      </Grid>
        
      </Grid>



</React.Fragment>
  );
}

export default Activity;
