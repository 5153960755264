import React, {useState, useEffect}  from 'react';
import {
  Link
} from "react-router-dom";
import { Typography} from '@mui/material/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { Card, CardMedia, CardContent, Stack, Avatar } from '@mui/material';


function HomeElement({activity, key})   {

    return (
      <>
      
        <Link  to='/activity' style={{ textDecoration: 'none', color: 'black'}}>
        <Card key={key} sx = {{ height: "auto", width:"100%", margin:2, background:"rgba(255,255,255, 0.6)" }}>

          <CardMedia 
            key={"activityCM" + key}
            image={"/img/work/"+activity.img}
            sx={{width:'90%', marginLeft:'5%', marginTop:'5%', aspectRatio:"1",  borderRadius: 3}}
            title={activity.name}
          />
        <CardContent key={"activityCC" + key}  >
          <Stack  key={"activityStack" + key} direction="row" alignItems="center">
          <Avatar key={"activityAvatar" + key} sx={{mr:2}} src={"/icons/"+activity.icon} variant="square"></Avatar>
          <Typography key={"activityTypo" + key} component={'span'} color="#333" className=".overlay" gutterBottom variant="body2" fontFamily="Tilt Warp">
                {activity.name}
          </Typography>
          </Stack>
          </CardContent>
        </Card>
        </Link>
      </>
    );
}

export default HomeElement;
