import React, { useLayoutEffect, useEffect}  from 'react';
import { styled } from '@mui/material/styles';
import {
  Link, useLocation, useNavigate
} from "react-router-dom";
import { AppBar, Box, Card, Chip, Typography, Menu, Container, Divider, Avatar, Button, Tooltip, MenuItem, BottomNavigation, BottomNavigationAction } from '@mui/material/';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Logo from '../layout/mini/Logo';
import BucketListMini from '../layout/mini/Bucketlist';
import { People } from '@mui/icons-material';
import List from '@mui/material/List';
import {ListItem, ListItemText, ListItemButton, ListItemIcon} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import {  faCompass } from '@fortawesome/free-solid-svg-icons'
import {  faSquarePlus } from '@fortawesome/free-solid-svg-icons'
import {  faBook } from '@fortawesome/free-solid-svg-icons'


import '../App.css';

const pages = ['create', 'share', 'explore'];



const LeftMenu = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [size, setSize] = React.useState([0, 0]);
  const location = useLocation();



  let navigate = useNavigate(); 
  const routeChange = (pathNew: string) =>{ 
    let path = pathNew; 
    navigate(path);
  }

  const iconSize = 'lg';

  useEffect(() => {

    if(location.pathname === "/home") {
      setValue(0);
    }
    else if(location.pathname === "/search") {
      setValue(1);
    }
    else if(location.pathname === "/explore") {
      setValue(2);
    }
    else if(location.pathname === "/create") {
      setValue(3);
    }
    else {
      setValue(-1);
    }
  }, [location] )
  return (
    
    <>
    <Box
      sx={{ display: { xs: 'none', sm: 'block' }, width: {sm:'100px', lg:'15vw'}}}
      
    
      >
      <Box m={1} mr={0} >
        <Logo/>

      <Card sx={{height:'150px', p:2 }} >
        <Link to="/home"  style={{ textDecoration: 'none', color: 'black'}}>
            <Stack direction="row" alignItems="center" m={2} className="leftMenuButton">
            <FontAwesomeIcon  icon={faHome} size={iconSize}/>
            <Typography sx={{display:{sm:'none', lg:'block'}}} ml={2} color="white" >Home</Typography>
          </Stack>
        </Link>     


        <Link to="/explore"  style={{ textDecoration: 'none', color: 'black'}}>
          <Stack direction="row" alignItems="center" m={2} mt={4} className="leftMenuButton">
            <FontAwesomeIcon icon={faCompass} size={iconSize}/>
            <Typography  sx={{display:{sm:'none', lg:'block'}}}  ml={2}>Explore</Typography>
          </Stack>
        </Link>

      </Card>

      </Box>
      <Box  m={1} mr={0}  >
        <Card sx={{height:'75vh', p:2, pt:0 }}>
          <Stack direction="row" alignItems="center" m={2} mt={4} className="leftMenuTitle">
            <FontAwesomeIcon icon={faBook} size={iconSize}/>
            <Typography  sx={{display:{sm:'none', lg:'block'}}}  ml={2}>Collection</Typography>
          </Stack>

        <Stack direction="row" spacing={2} sx={{display:{sm:'none', lg:'block'}}} >
          <Chip label="Bucketlist" clickable/><Chip label="Activity" clickable/>
        </Stack>
        <BucketListMini/>
        </Card>
      </Box>



      </Box>
</>
  );
};
export default LeftMenu;
