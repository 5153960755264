import React, { Component, ErrorInfo, ReactNode } from "react";
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';


import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";

import BottomMenu from './layout/mini/BottomMenu';
import LeftMenu from './layout/LeftMenu';
import ProductPage from './layout/ProductPage';
import BucketList from './layout/BucketList'
import Activity from './layout/Activity'
import User from './layout/User'
import Home from './layout/Home'
import SignUp from './layout/SignUp'
import Explore from './layout/Explore'
import TopMenu from './layout/mini/TopMenu'

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Bucketlist() {

  return (

    <Router>
      <ErrorBoundary>
      <DndProvider backend={TouchBackend}>
       <App />
       </DndProvider>
      </ErrorBoundary>
    </Router>


  );
}

function App() {
  let query = useQuery();

  return (
    <>


     <Routes>
        <Route path="/home" element={  <BottomMenu/>} />
        <Route path="/bucketlist" element={  <BottomMenu/>} />
        <Route path="/share" element={  <BottomMenu/>} />
        <Route path="/create" element={  <BottomMenu/>} />
        <Route path="/explore" element={  <BottomMenu/>} />
        <Route path="/activity" element={<BottomMenu/>} />
        <Route path="/user" element={<BottomMenu/>} />
      </Routes>  
 {/* Left Menu ?  */}

    <Routes>
        <Route path="/home" element={<Box id="leftMenu"><LeftMenu/></Box>} />
        <Route path="/bucketlist" element={   <Box id="leftMenu"><LeftMenu/></Box>} />
        <Route path="/activity" element={   <Box id="leftMenu"><LeftMenu/></Box>} />
        <Route path="/share" element={  <Box id="leftMenu"><LeftMenu/></Box>} />
        <Route path="/create" element={  <Box id="leftMenu"><LeftMenu/></Box>} />
        <Route path="/explore" element={  <Box id="leftMenu"><LeftMenu/></Box>} />
        <Route path="/user" element={<Box id="leftMenu"><LeftMenu/></Box>} />
      </Routes> 
     {/* Content with left menu ?  */}  



        
      <Routes>
      <Route path="/home" element={<>
          <Box id="main" sx={{ width: { xs: '100vw', sm: 'calc(100vw - 120px)', lg:"85vw" },  left: { xs:'0', sm:'120px', lg:'15vw'}}}>
            <Box m={1}  sx={{position:"relative", height:"100vh"}}>
            <Card >
              <Card sx={{ mr:1, border: "none", boxShadow: "none", position:'absolute', height:"120%", width:'100%', zIndex:"-10", background:'linear-gradient(to bottom, #54fd, #1121);'}}></Card>
                    <PerfectScrollbar><TopMenu/><Home /></PerfectScrollbar>
                    </Card></Box></Box></>} />
        

        <Route path="/bucketlist" element={<>
          <Box id="main" sx={{ width: { xs: '100vw', sm: 'calc(100vw - 120px)', lg:"85vw" },  left: { xs:'0', sm:'120px', lg:'15vw'}}}>
            <Box m={1}  sx={{position:"relative", height:"100vh"}}>
            <Card >
              <Card sx={{ mr:1, border: "none", boxShadow: "none", position:'absolute', height:"120%", width:'100%', zIndex:"-10", background:'linear-gradient(to bottom, #16af, #1121);'}}></Card>
                    <PerfectScrollbar><TopMenu/><BucketList /></PerfectScrollbar>
                    </Card></Box></Box></>} />

        <Route path="/activity" element={<>
          <Box id="main" sx={{ width: { xs: '100vw', sm: 'calc(100vw - 120px)', lg:"85vw" },  left: { xs:'0', sm:'120px', lg:'15vw'}}}>
            <Box m={1}  sx={{position:"relative", height:"100vh"}}>
            <Card >
              <Card sx={{ mr:1, border: "none", boxShadow: "none", position:'absolute', height:"120%", width:'100%', zIndex:"-10", background:'linear-gradient(to bottom, #6f9a, #1121);'}}></Card>
                    <PerfectScrollbar><TopMenu/><Activity /></PerfectScrollbar>
                    </Card></Box></Box></>} />                    
        
        
        <Route path="/explore" element={<>
          <Box id="main" sx={{ width: { xs: '100vw', sm: 'calc(100vw - 120px)', lg:"85vw" },  left: { xs:'0', sm:'120px', lg:'15vw'}}}>
            <Box m={1}  sx={{position:"relative", height:"100vh"}}>
            <Card>    
            <Card sx={{ mr:1, border: "none", boxShadow: "none", position:'absolute', height:"120%", width:'100%', zIndex:"-10", background:'linear-gradient(to bottom, #16af, #1121);'}}></Card>
            <PerfectScrollbar><TopMenu/><Explore /></PerfectScrollbar>
          </Card></Box></Box></>} />
        
        <Route path="/user" element={<>
          <Box id="main" sx={{ width: { xs: '100vw', sm: 'calc(100vw - 120px)', lg:"85vw" },  left: { xs:'0', sm:'120px', lg:'15vw'}}}>
            <Box m={1}  sx={{position:"relative", height:"100vh"}}>
            <Card>   
            <Card sx={{ mr:1, border: "none", boxShadow: "none", position:'absolute', height:"120%", width:'100%', zIndex:"-10", background:'linear-gradient(to bottom, #66af, #1121);'}}></Card>
            <PerfectScrollbar><TopMenu/><User /></PerfectScrollbar>
            </Card></Box></Box></>} />
        
        
      </Routes> 
      



      

    {/* Content without left menu ?  */} 
    <Routes>
      <Route path="/" element={<ProductPage />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<SignUp />} />

    </Routes>   
      {/* <Footer/>  */}


</>

   
  );
}

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}


class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <h1>Sorry.. there was an error</h1>;
    }

    return this.props.children;
  }
}
