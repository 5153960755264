import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  typography: {
    fontFamily: [
      'Heebo',
      'Roboto',
      'Dosis',
      'Arial',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      }, 
    }, 
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          border:0,
        },
      }, 
    }, 
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          border: 0,
          borderRight:0,
        },
      }, 
    }, 
  },
  palette: {
    mode: "dark",
    background: {
      default: '#111',
      paper: '#1121',
      
    },
    primary: {
      light: '#ddd',//'#5ff8dc',
      main: '#ddd', //00b39c
      dark: '#ddd',
      contrastText: '#fff',
    },
    secondary: {
      light: '#d8ecffff',
      main: '#2f5b84ff',
      dark: '#163552ff', //4b00c5
      contrastText: '#d8ecffff',
    },
    text: {
      primary: '#ddd',
    },
   
  }
});

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
  <App />
</ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
