import React, {useState, useEffect} from 'react';
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {Container, Card, Box, Grid, Avatar, Stack, Typography, Divider, Chip} from '@mui/material'
import {SortableItem} from './mini/SortableItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faSpinner, faHeart, faSort } from '@fortawesome/free-solid-svg-icons'


import dreamlist from '../data/bucketlistUser.json';
import themes from '../data/themes.json';

export default function User() {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  const [items, setItems] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60]);
  const [dreamList, setDreamList] = useState(dreamlist);
  const [showActionButtons, setShowActionButtons] = React.useState(-1);
  const [chipSelected, setChipSelected] = React.useState([false]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

    const handleClickChip = (index, theme) => {
    const newBoolArray = [...chipSelected];
    newBoolArray[index] = !newBoolArray[index];
    setChipSelected(newBoolArray);
    console.log(showThemesSelected());
  };

  
  const handleActivityClick = (id) => {

  };

  const color = '#35e6'

  const showThemesSelected = (() => {
    var themeList; 
    var index = 0;
    themes.map((theme, key) => {
      if(chipSelected[key]) {
        console.log(themes[key].theme);
        themeList.fill(themes[key].theme);
        index++;
      }
      return themeList;
    })
  });


  return (

    <Container >

     
     <Stack direction="row" mt={2} mb={3} spacing={2}>
       <Avatar src="/img/quentinrenaud.svg"></Avatar>
       <Stack direction="column">
        <Typography>Quentin Renaud</Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <FontAwesomeIcon icon={faCheckCircle} /><Typography variant="body2">33 done</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <FontAwesomeIcon icon={faSpinner} /><Typography variant="body2">14 in progress</Typography>
        </Stack>        
        <Stack direction="row" alignItems="center" spacing={1}>
          <FontAwesomeIcon icon={faHeart} /><Typography variant="body2">75 dreams</Typography>
        </Stack>        
       </Stack>
     </Stack>
    

    

     <Stack mt={1} direction="row" className="listIconsBucketlist" sx={{overflowX:"scroll"}}>


{themes.map( (theme, key) => {
  return (
   <> <div key={"divOpacity" +key} style={{opacity: chipSelected[key] ? 1 : 0.2}}>
      <Chip key={"Chip"+key} size="medium" sx={{mt:2, mr:1}} label={theme.theme} onClick={() => handleClickChip(key, theme.theme)} style={{color:'white', backgroundColor:theme.color}} />
  </div>
  </>
  )
})}

</Stack>
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}

    >
      <SortableContext 
        items={items}
      >
      <Grid container  justifyContent="center">
        {items.map(id => 
        <Grid alignItems="center" color="#ddd" justifyContent="center" textAlign="center" item lg={2} md={3} sm={4} xs={4} mt={4}>
          <SortableItem key={id} onClick={handleActivityClick()} number={items.indexOf(id)} id={id} name={dreamlist[id].name} icon={dreamlist[id].icon} style={dreamlist[id].status}/>
          </Grid>)}
      </Grid>
      </SortableContext>
    </DndContext>
      
    </Container>

    
  );
  
  function handleDragEnd( event) {

    const {active, over} = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        console.log("DragEnd");
        return arrayMove(items, oldIndex, newIndex);
  
    
      });
    
  }
  }
}