
import * as React from 'react';
import {useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import {
  Link, useLocation
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PlayCircle from '@mui/icons-material/PlayCircle';
import ExploreIcon from '@mui/icons-material/Explore';
import mantras from '../data/mantras.json';

import LogoWhite from '../layout/mini/LogoWhite';
import ThemeList from './mini/ThemeList'

import '../App.css';

const CustomButton = styled(Button)({
  textTransform: 'none',
  width: 200,
  height: 80,
  color: "white",
  fontSize: 30,
  fontWeight: 600,
  borderRadius: 20,
});

function ProductPage() {

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  const [checked, setChecked] = React.useState([1]);
  const IMAGE_SIZE = 60;

  function handleToggle(value: number)  {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };



  return (






<>    <div className="video-container">
    <video autoPlay loop muted id="video">
        <source src="/movies/trailer.mp4" type='video/mp4' />
        </video>
        </div>










      <Container maxWidth="md">



        <Grid container minHeight="100vh" pl={3} pt={2} >

          <Grid xs={12}><Typography variant="h2" color="white" pb={2} textAlign="center" sx={{ fontWeight: 600, fontSize: '8vh' }} pt={12}>Empowering Dreams Together</Typography></Grid>
          <Grid xs={12}>
          <Stack
          direction="row"   
          justifyContent="center"
          alignItems="center"
          spacing={1}>
   
   <Link to="/explore"> <img className="rotate" src="/logo.png" width={150} height={150} /> </Link>

      
          </Stack>  
          </Grid>

          <Grid  xs={6} md={3} pt={3} pr={2} pl={2} container direction="column" justifyContent="center" textAlign="center" alignItems="center"><img src="/icons/dreams.png" width={IMAGE_SIZE} height={IMAGE_SIZE} /><Typography color="white" variant="h5" sx={{ fontWeight: 500, fontSize: '3vh' }}>Dream bigger</Typography></Grid>
          <Grid xs={6} md={3} pt={3} pr={2} pl={2} container direction="column" justifyContent="center" textAlign="center" alignItems="center"><img src="/icons/motivation.png" width={IMAGE_SIZE} height={IMAGE_SIZE} /><Typography color="white" variant="h5" sx={{ fontWeight: 500, fontSize: '3vh' }}>Motivation increased</Typography></Grid>
          <Grid xs={6} md={3} pt={3} pr={2} pl={2} container direction="column" justifyContent="center" textAlign="center" alignItems="center"><img src="/icons/goal.png" width={IMAGE_SIZE} height={IMAGE_SIZE} /><Typography color="white" variant="h5" sx={{ fontWeight: 500, fontSize: '3vh' }}>Goals achived</Typography></Grid>
          <Grid xs={6} md={3} pt={3} pr={2} pl={2} container direction="column" justifyContent="center" textAlign="center" alignItems="center"><img src="/icons/accomplishment.png" width={IMAGE_SIZE} height={IMAGE_SIZE} /><Typography color="white" variant="h5" sx={{ fontWeight: 500, fontSize: '3vh' }}>Share and inspire</Typography></Grid>




        </Grid>
        
      </Container>



    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white"
      }}
    >
      
    <Container >
        <Stack spacing={2} mt={5} pl={5} textAlign="left">
          <Typography variant="h6" color="#ddd">Selecting your dreams you want to transform into reality can be a life-changing experience to help you discover what you truly want out of life. It can help you set goals, prioritize experiences, and create a sense of direction and purpose. You get to dream big and set your sights on things that you may have never thought possible. It can help you break out of your comfort zone, challenge yourself, and experience new things that you may have never imagined.</Typography>
          <Typography variant="h6" color="#ddd"><b>Gofordo</b> is a reminder to live your life to the fullest and not take any moment for granted. It can be a form of self-discovery, helping you to better understand what truly matters to you in life.</Typography>
          <Typography variant="h6" color="#ddd" pb={4}>Ultimately, it's a powerful tool for personal growth, self-improvement, and living a more fulfilling life. So why not take the time to create your own life today and start living your dreams ?</Typography>
        
        </Stack>
      </Container></div>
      <ThemeList/></>





  );
}

export default ProductPage;
